import { template as template_41189bf6eaa748e4a5edf655968d5e79 } from "@ember/template-compiler";
const SidebarSectionMessage = template_41189bf6eaa748e4a5edf655968d5e79(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
