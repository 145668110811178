import { template as template_3c9fc8af070744f8ab9b97e69b0b2cd2 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_3c9fc8af070744f8ab9b97e69b0b2cd2(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
