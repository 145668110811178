import { template as template_62aafbac1add40129b91da4e7f9577ec } from "@ember/template-compiler";
const FKLabel = template_62aafbac1add40129b91da4e7f9577ec(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
