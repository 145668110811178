import { template as template_f702445d54c5430dae3653e12fc16e05 } from "@ember/template-compiler";
const FKText = template_f702445d54c5430dae3653e12fc16e05(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
